if(!$.fn.loading){
    $.fn.loading = function () {
        var args = arguments;
        this.each(function () {
            if (!$(this).data("loading")) {
                var $loading = $('<div class="bazaLoding"><i class="fa fa-spinner fa-spin"></i></div>');
                $(this).append($loading);
                this.oldPos = $(this).css("position");
                $(this).css("position", "relative").data("loading", $loading);
            } else {
                if (args[0] == "hide") {
                    $(this).css("position", this.oldPos).data("loading").hide();
                } else {
                    $(this).css("position","relative").data("loading").show();
                    this.oldPos = $(this).css("position");
                }
            }
        });
    };
}

$.isBoolean = function(value){
    return typeof value === "boolean";
}

$.isString = function(value){
    return typeof value === "string";
}


$.isUndefined = function(value){
    return typeof value === "undefined";
}