import '../third/fileupload/fileupload';
import '#/js/plugin/jquery.form.js';
import { errorMessage } from '#/js/util/tools.js';


export default {
    data() {
        return {
            actionUrl: '',
            users: [],
            comments: [],
            imagePreviewList: [],
            attachmentPreviewList: []
        }
    },
    computed: {
        commentCount() {
            return this.comments.length;
        },
        isEmailNotice() {
            return this.isEmailNotic
        },
        getPublicComments() {
            // return ko.utils.arrayFilter(this.comments, function (comment) {
            //     return comment.isPublic === undefined || comment.isPublic === true;
            // });
            this.comments.forEach((comment) => {
                if(comment.isPublic === undefined || comment.isPublic === true){
                    return comment;
                }
            })
        },
        getInternalComments() {
            // return ko.utils.arrayFilter(this.comments, function (comment) {
            //     return comment.isPublic === false;
            // });
            this.comments.forEach((comment) => {
                if(comment.isPublic === false){
                    return comment;
                }
            })
        },
        hasInternalComment() {
            var hasInternal = false;

            this.comments.forEach((comments) => {
                if (comment.isPublic === false) {
                    hasInternal = true;
    
                    // 停止遍历
                    return false;
                }
            })

            return hasInternal;
        }
    },
    watch: {
        commentCount: function(val) {
            this.$emit('updateCommentCount', val)
        }
    },
    mounted() {
        this.defaults = {
            userId: this.$store.state.user.userInfo.id,
            commentType: "job",
            atPersonLength: 8,
            start: 0,
            defaultTake: 5,
            requestTime: '',
            privacyTips: "只有被@的人才能看到评论",
            publicTips: "所有人能看到评论",
            placeholderText: "",
            atReg: /(@[\S\.]+)[\s]*/g,
            emailReg: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/g,
            prefetch: true, //实例化之后是否立即取数据
            canPost: true, // 是否可以发表消息
            isTeam: false,//团队
            isEmail: false
        };

        let options = {
            commentType: this.commentType,
            placeholderText: this.placeholderText,
            prefetch: this.prefetch,
            canPost: this.canPost || true,
            isTeam: this.team || false,
            isEmail: this.isEmail || false,
            commentTitle: this.commentTitle
        };

        $.isPlainObject(options) && $.extend(this.defaults, options);

        this.$wrap = $('#commentPanelId');
        if (this.$wrap.length == 0) {
            throw new Error("need Comments Template");
        }
        this.userList = [];
        this.token = this.verificationToken;
        this.$privacy = this.$wrap.find("#privacy");
        this.$atlist = this.$wrap.find("#atlist");
        this.$form = this.$wrap.find("form");
        this.$comment = this.$wrap.find("#comment");
        this.imagePreviewCount = 0;
        this.attachmentPreviewCount = 0;
        this.$imagePreview = this.$wrap.find(".comment-image.preview");
        this.$attachmentPreview = this.$wrap.find(".comment-attachment.preview");
        this.$imagesList = this.$wrap.find(".comment-image.image-list");
        this.$totalWrap = this.$wrap.prev().find(".text-primary");
        this.$more = this.$wrap.find(".more");
        this.refreshNodes();

        // 直接初始化，先不加载用户信息
        this.$form.find("textarea[name=comment]").AtSelect({
            getUserInfo: this.getInputAtUserInfo,
            source: [],
            valueDefault: "nickname",
            filterField: "nickname,realName",
            atTriggerReg: /.*/,
            commentType: this.defaults.commentType,
            insertTpl: '@{{nickname}} ',
            maxItems: this.defaults.atPersonLength,
            menuItemTpl: `<img width="16" src="{{avatar}}" /> <span class="team-tip" style="{{isFirmTeam}}">团队</span> <span class="realName">{{realName}}</span> <span class="nickname">@{{nickname}}</span>`
        });

        this.bindEvent();
        // check if view from email
        this.emailReply();
    },
    methods: {
        refreshNodes() {
            this.$comment.attr("placeholder", this.defaults.placeholderText);
            // this.actionUrl = "/" + this.defaults.commentType + "/" + this.commentObjectInfo.id + "/Comment ";
            this.$form.attr("action", window._host.portal + "/" + this.defaults.commentType + "/" + this.commentObjectInfo.id + "/Comment ");
        },
        refresh(id) {
            if (this.defaults.prefetch === true) {
                this.getCommentList(id || this.commentObjectInfo.id);
            }
            this.reset();
            this.refreshCommentList(id || this.commentObjectInfo.id);
            this.refreshNodes();
        },
        refreshAndgetCommentList(start, take, focus) {
            var originStart = this.defaults.start;
            this.refreshAndGetCommentList(start, take, focus);
            if ($.isNumeric(start)) {
                this.defaults.start = originStart;
            }
            this.refreshNodes();
            this.resetCommentList();
        },
        reset() {
            this.defaults.start = 0;
            this.$more.addClass('hide');
        },
        bindEvent() {
            var _self = this;
            this.$privacy.on("click", function (e) {
                $(this).parent().addClass("open");
                e.stopPropagation();
            });
            this.$privacy.parent().find(".dropdown-menu a").on("click", function (e) {
                var $parent = _self.$privacy.parent(),
                    $this = $(this);
                $parent.find("input[name=privacy]").val($this.data("value"));
                _self.$privacy.find(".fa")[0].className = $this.find(".fa")[0].className;
                _self.$privacy.find("span").text($this.text());
            }).each(function () {
                if ($(this).data("value") == 1) {
                    $(this).popover({
                        placement: "left",
                        content: _self.defaults.privacyTips
                    });
                } else {
                    $(this).popover({
                        placement: "left",
                        content: _self.defaults.publicTips
                    });
                }
            });
            this.$atlist.on("click", function () {
                $(this).parent().addClass("open");
            });
            $(document).click(function (e) {
                if (e.target != _self.$privacy[0] && e.target.parentNode != _self.$privacy[0]) {
                    _self.$privacy.parent().removeClass("open");
                }
                if ($(e.target).parents(".atList").length == 0 && e.target != _self.$atlist[0] && e.target.parentNode != _self.$atlist[0]) {
                    _self.$atlist.parent().removeClass("open");
                }
            });

            this.$comment.bind("blur keyup", function () {
                var maxLength = parseInt(_self.$comment.data("maxlength")),
                    $error = _self.$wrap.find("#error"),
                    contents = $(this).val(),
                    userCounts = 0,
                    exceedCount = 0,
                    emailLengths = 0;
                contents = contents.replace(_self.defaults.emailReg, function ($1) {
                    emailLengths += $1.length;
                }).replace(_self.defaults.atReg, function () {
                    userCounts++;
                    return '';
                });
                exceedCount = contents.length + emailLengths + userCounts * 47 - maxLength;
                if (exceedCount > 0) {
                    $error.html("已超出" + exceedCount + "字").removeClass("hide");
                } else {
                    $error.addClass("hide");
                }
            });

            this.$comment.on('keydown', function (e) {
                var alt = e.altKey, shift = e.shiftKey, ctrl = e.ctrlKey;
                var key = e.keyCode;
                if (key === 13 && ctrl) {
                    _self.add();
                }
            });
            // 回复
            this.$wrap.delegate('.reply', 'click', function () {
                var str = $(this).parents('.detail').find('span[type=nickname]').text() + ' ';
                var hasSecrect = $(this).parents('.detail').find('.secret.hide');
                console.log(hasSecrect)
                if(hasSecrect.length == 0) {
                    _self.privacy = 1;
                    _self.disablePrivacy = true;
                } else {
                    _self.privacy = 0;
                    _self.disablePrivacy = false;
                }
                _self.$comment.val(str);
                _self.$comment.focus();
            });

            // 公开/加密
            this.$wrap.delegate('.public, .encrypt', 'click', function () {
                var $this = $(this),
                    $thisParent = $this.parents('div.commentid'),
                    $thisSecret = $thisParent.find('.secret'),
                    commentId = $thisParent.data("commentid"),
                    encryptHtml = '<i class="fa fa-lock"></i>  私密',
                    publicHtml = '<i class="fa fa-eye"></i>  公开';
                _request({
                    url: '/Communication/TogglePrivacy',
                    method: 'POST',
                    data: {
                        commentId: commentId
                    }
                }).then(res => {
                    if ($this.hasClass("encrypt")) {
                        $this.removeClass("encrypt").addClass("public").html(publicHtml);
                        $thisSecret.removeClass("hide");
                    } else {
                        $this.addClass("encrypt").removeClass("public").html(encryptHtml);
                        $thisSecret.addClass("hide");
                    }
                })
                // $.post(currentHost + "/Communication/TogglePrivacy", {"commentId": commentId},
                //     function (result) {
                //         if (result["succeeded"]) {
                //             if ($this.hasClass("encrypt")) {
                //                 $this.removeClass("encrypt").addClass("public").html(publicHtml);
                //                 $thisSecret.removeClass("hide");
                //             } else {
                //                 $this.addClass("encrypt").removeClass("public").html(encryptHtml);
                //                 $thisSecret.addClass("hide");
                //             }
                //         } else {
                //             shortTips(errorMessage(result));
                //         }
                //     }
                // );
            });
            var toggleTopUrl = "/Comment/{commentId}/StickyOnTop";
            // 置顶/取消置顶
            this.$wrap.delegate('.is-top, .not-top', 'click', function () {
                var $this = $(this),
                    $thisParent = $this.parents('div.commentid'),
                    commentId = $thisParent.data("commentid"),
                    url = toggleTopUrl.replace('{commentId}', commentId);
                _request({
                    url: url,
                    method: 'POST'
                }).then(res => {
                    var start = 0, take = _self.defaults.start + _self.defaults.defaultTake;
                    _self.refreshAndgetCommentList(start, take, true);
                })
                // $.post(url, function (result) {
                //     if (result["succeeded"]) {
                //         var start = 0, take = _self.defaults.start + _self.defaults.defaultTake;
                //         _self.refreshAndgetCommentList(start, take, true);
                //     } else {
                //         shortTips(errorMessage(result));
                //     }
                // });
            });

            // 删除
            this.$wrap.delegate('.delete', 'click', function () {
                let self=this;
                var $this = $(this),
                    $thisParent = $this.parents('div.commentid'),
                    $isDelPop = $("#isDelPop"),
                    commentId = $thisParent.data("commentid");

                // confirm('删除评论', "确定删除这条评论吗", {
                //     specialClassName: 'delete-comment-dialog'
                // }).then(function (result) {
                //     if (result === false) {
                //         return;
                //     }

                //     $.post("/Communication/Delete", {"commentId": commentId}, function (result) {
                //         if (result["succeeded"]) {
                //             $thisParent.remove();
                //             _self.refresh();
                //             shortTips('评论删除成功');
                //         } else {
                //             shortTips(errorMessage(result));
                //         }
                //     });
                // });
                _self.deleteCommentId = commentId;
                _self.deleteDialogVisible = true;
            });

            // 上传图片
            this.uploadImage();

            // 删除图片
            this.$imagePreview.delegate('.delete', 'click', function () {
                var $item = $(this).parents('.image-item');
                var idx = $item.index();
                $item.remove();
                _self.imagePreviewList.splice(idx, 1);
                _self.imagePreviewCount--;
            });
            // 删除附件
            this.$attachmentPreview.delegate('.delete', 'click', function (e) {
                e.stopPropagation();
                var $item = $(this).parents('.attachment-item');
                var idx = $item.index();
                // $item.remove();
                _self.attachmentPreviewList.splice(idx, 1);
                _self.attachmentPreviewCount--;
            });

            // 阻止发表评论的tip
            // this.$wrap.find('#publish.btn-disabled') && this.$wrap.find('#publish.btn-disabled').popover({
            //     trigger: 'hover',
            //     placement: "bottom",
            //     content: '您已被禁止推荐，不能发表评论！'
            // });
        },
        uploadImage() {
            var _self = this, $el = this.$wrap.find('#commentWithImageId');
            $el.on('click', function () {
                if (_self.maxLimit == 1 && _self.attachmentPreviewCount >= 1) {
                    shortTips('评论最多只能上传1个附件');
                    return false;
                }
                if (_self.imagePreviewCount + _self.attachmentPreviewCount >= 8) {
                    shortTips('最多只能上传8个附件及图片');
                    return false;
                }
            });
            var fileExts = 'htm|html|doc|xlsx|swf|fla|pdf|psd|rar|iso|zip|docx|xls|txt|png|ppt|pptx|avi|7z|rmvb|mpg|mov|mp3|mid|wav|rm|ape|flac';
            var fileExtsReg = new RegExp('.(' + fileExts + ')$');
            var fileExtsTip = "请上传" + fileExts.replace(/\|/g, ',') + "格式的图片！";
            var imageExts = 'jpg|jpeg|png';
            var imageExtsReg = new RegExp('.(' + imageExts + ')$');
            var imageExtsTip = "请上传" + imageExts.replace(/\|/g, ',') + "格式的文件！";

            $el.fileupload({
                autoUpload: true,//是否自动上传
                url: window._host.portal + "/Data/UploadAndShrinkImage",
                dataType: 'json',
                headers: {
                    'RequestVerificationToken': _self.token,
                    "AuthorizationLBD": _getCookie('access_token')
                },
                crossDomain: true,
                xhrFields: { withCredentials: true },
                formData: {__RequestVerificationToken: _self.token, Purpose: 6, Crop: true},
                singleFileUploads: false,
                limitMultiFileUploads: 1,
                add: function (e, data) {
                    var fileFullame = data.files[0].name || '';
                    fileFullame = fileFullame.toLowerCase();
                    var type = imageExtsReg.test(fileFullame) ? 1 : fileExtsReg.test(fileFullame) ? 2 : 0;
                    if (type === 0) {
                        shortTips("请上传文本文档或图片文件");
                        return false;
                    }
                    if (type === 1) {
                        data.url = window._host.portal + "/Data/UploadAndShrinkImage";
                    } else {
                        data.url = window._host.portal + "/Data/UploadFile";
                    }
                    return data.submit().done(function (rtn) {
                        if (rtn.succeeded) {
                            if (type === 1) {
                                _self.imagePreviewList.push(rtn.data);
                                _self.imagePreviewCount++;
                            } else {
                                _self.attachmentPreviewList.push(rtn.data);
                                _self.attachmentPreviewCount++;
                            }
                            // shortTips(rtn.message || rtn.errorMessage || '系统异常，请稍后重试');
                            // shortTips('上传成功!');
                        } else {
                            shortTips(errorMessage(rtn));
                        }
                    }).fail(function (rtn) {
                        // shortTips(rtn.message || rtn.errorMessage || '系统异常，请稍后重试');
                        shortTips(errorMessage(rtn));
                    });
                }
            });
        },
        updateTotalComments(total) {
            if (total > 0) {
                this.$totalWrap.html(total);
                this.$totalWrap.parent().removeClass("hide").next().addClass("hide");
            } else {
                this.$totalWrap.parent().addClass("hide").next().removeClass("hide");
            }
        },
        viewLargeImage() {
            this.$wrap.find('.comment-image.image-list').each(function () { // the containers for all your galleries
                console.log($(this))
                try{
                  $(this).magnificPopup({
                      delegate: '.image-item',
                      type: 'image',
                      closeOnContentClick: false,
                      closeBtnInside: false,
                      mainClass: 'mfp-with-zoom mfp-img-mobile',
                      image: {
                          verticalFit: true,
                      },
                      gallery: {
                          enabled: true
                      },
                      zoom: {
                          enabled: true,
                          duration: 300,
                          opener: function (element) {
                              return element.find('img');
                          }
                      }
                  });
                }catch(e) {
                }
            });
        },
        emailReply() {
            let _self = this;
            /\?/g.test(window.location.href) && (function () {
                var getData = _self.getUrlData(),
                    $parent = _self.$privacy.parent();
                // 过滤hash
                Object.keys(getData).forEach((key) => {
                    getData[key] = getData[key].split('#')[0];
                })
                if (getData['privacy'] == 1) {
                    $parent.find("input[name=privacy]").val(getData['privacy']);
                    _self.$privacy.find(".fa")[0].className = 'fa fa-lock';
                    _self.$privacy.find("span").text('私密');
                }
                var atNickname = getData['atNickname'] || '';
                atNickname = decodeURI(atNickname);
                _self.$comment.val('@' + atNickname + ' ');
                if (atNickname) {
                    $(function () {
                        $(window).scrollTop(_self.$comment.offset().top);
                        _self.$comment.on('atSelect.finish', function () {
                            var select = _self.$comment.data('AtSelect');
                            if (select) {
                                select.lookup();
                            }
                        });
                    });
                }
            })();
        },
        getUrlData() {
            var href = window.location.href,
                hrefStr = href.split('?')[1],
                getArr = hrefStr.split('&'),
                getData = {};
            for (var i = 0, length = getArr.length; i < length; i++) {
                var str = getArr[i].split('='),
                    key = str[0],
                    val = str[1];
                getData[key] = val;
            }
            return getData;
        },
        selectPerson(item, event) {
            var sourceValue = this.$comment.val();
            this.$comment.val(sourceValue + "@" + item.nickname + " ");
            $(event.currentTarget).parents(".open").removeClass("open");
            this.atListPopoverVisible = false;
        },
        stopEvent(event) {
            if (event.keyCode == 13) {
                event.stopImmediatePropagation();
                event.stopPropagation();
                return false;
            }
            return true;
        },
        getUserData(data) {
            var arr = JSON.parse(JSON.stringify(data));
            //放开职位AT人列表展示数量限制
            // if (this.defaults.commentType === 'job' && arr.length > this.defaults.atPersonLength) {
            //     var itemsLength = arr.length;
            //     var otherUsers = arr.slice(itemsLength - 2, itemsLength);
            //     arr = arr.slice(0, this.defaults.atPersonLength - 2);
            //     arr = arr.concat(otherUsers);
            // }
            return arr;
        },
        searchUsers(event) {
            var value = $.trim($(event.currentTarget).val()),
                result = [], reg, arr = [];
            if (!value) {
                arr = this.getUserData(this.userList);
            } else {
                arr = JSON.parse(JSON.stringify(this.userList));
            }
            reg = new RegExp(value);
            this.users = [];
            for (var i = 0; i < arr.length; i++) {
                if (this.users.length >= this.defaults.atPersonLength) {
                    break;
                }
                if (reg.test(arr[i].nickname) || reg.test(arr[i].realName)) {
                    if (value) {
                        arr[i].type = 0;
                    }
                    this.users.push(arr[i]);
                }
            }
            if (event.keyCode == 13 && this.users.length == 1) {
                this.selectPerson(this.users[0], event);
            }
        },
        getInputAtUserInfo(callback) {
            var param = {
                searchScope: this.commentObjectInfo.isMyJob ? 1 : 0,
                showFirmTeam: this.defaults.isTeam
            };
            return _request({
                url: "/" + this.defaults.commentType + "/" + this.commentObjectInfo.id + "/Comment/AtUsers",
                method: 'GET',
                data: param
            }).then(res => {
                this.users = this.getUserData(res);
                // this.userList = res;
                this.userList = res.map(it => {
                    if(it.isFirmTeam) {
                        it.avatar = require('@src/assets/images/teamLogo.png');
                    }
                    return it;
                });
                $.grep(this.userList, function (a) {
                    return a.isRelated = true;
                });

                callback && callback();
                return res;
            })
            // return $.get(currentHost + "/" + this.defaults.commentType + "/" + this.commentObjectInfo.id + "/Comment/AtUsers", param).then(deferResult).then((result) =>{
            //     callback && callback();
            //     return $.Deferred().resolve(result.data);
            // });
        },
        getClickAtUserInfo() {

            this.getInputAtUserInfo().then(r => {
                this.users = this.getUserData(r);
                this.userList = r;
                $.grep(this.userList, function (a) {
                    return a.isRelated = true;
                });
            });
        },
        insertComment(comment) {
            var idx = 0;
            var comments = this.comments;
            comments.forEach((v, k) => {
                if (v.hasSticky) {
                    idx = k;
                    return false;
                }
            })
            comments.splice(idx, 0, comment);
        },
        ajaxContent(callback) {
            let _self = this;

            var param = Object.assign({}, {
                isSendMessageToMyself: this.defaults.commentType === "job" ? false : true
            }, {attachmentInfos: this.getAttachmentInfos()});

            // let formData = new FormData();
            // formData.append('isSendMessageToMyself', this.defaults.commentType === "job" ? false : true);
            // formData.append('attachmentInfos', this.getAttachmentInfos());
            // formData.append('atTeamIds', '');
            // formData.append('viewLink', location.href.replace(/https?:\/\// + location.host, ''));

            // _request({
            //     url: this.add,
            //     method: 'POST',
            //     data: param
            // })

            this.$form.ajaxSubmit({
                data: param,
                headers: {
                    "AuthorizationLBD": _getCookie('access_token'),
                },
                beforeSubmit: function (arr, $form) {
                    var ids = [],
                        unMatchedName = [],
                        content = '';
                    arr.push({
                        name: "atTeamIds",
                        required: false,
                        type: "hidden",
                        value: "",
                    });
                    arr.push({
                        name: "viewLink",
                        required: false,
                        type: "hidden",
                        value: location.href.replace('http://' + location.host, ''),
                    })
                    var atTeamIds = arr[arr.length - 2];
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i].name == "comment") {
                            content = arr[i].value;
                            arr[i].value = arr[i].value.replace(_self.defaults.atReg, function ($1, $2) {
                                var tag = false;
                                for (var j = 0; j < _self.userList.length; j++) {
                                    if ("@" + _self.userList[j].nickname.toLowerCase() == $2.toLowerCase()) {
                                        ids.push(_self.userList[j].memberIds);
                                        var isFirmTeam = _self.userList[j].isFirmTeam;
                                        if (isFirmTeam) {
                                            if (atTeamIds.value) {
                                                atTeamIds.value += ',';
                                            }
                                            atTeamIds.value += _self.userList[j].id;
                                        }
                                        return "[userId:'" + _self.userList[j].id + "'] ";
                                    }
                                }
                                unMatchedName.push($2.replace("@", ""));
                                return $1;
                            });
                        }
                        if (arr[i].name === "atUserIds") {
                            arr[i].value = ids;
                        }
                    }
                    arr.push({
                        name: "otherAtNicknames",
                        required: false,
                        type: "hidden",
                        value: unMatchedName,
                    });
                    for(var i =0;i<arr.length;i++){
                        if(arr[i].name == "isEmailNotice"){
                            arr.splice(i,1);
                            break;
                        }
                    }
                    arr.push({
                        name: "isEmailNotice",
                        required: false,
                        type: "hidden",
                        value: _self.isEmailNotice
                    });
                    $form.loading();
                },
                success: function (result) {
                    _self.$form.data("loading").hide();
                    if (result.succeeded) {
                        shortTips("评论发表成功");
                        _self.$form.find('#comment').val('');
                        var start = 0, take = _self.defaults.start + _self.defaults.defaultTake;
                        _self.refreshAndgetCommentList(start, take, true);
                    } else {
                        shortTips(result.message || result.errorMessage || '系统异常，请稍后重试');
                    }
                    callback && callback();
                },
                complete: function () {
                    _self.confirmAtNoticeLoading = false;
                    _self.confirmWithoutAtLoading = false;
                }
            })
        },
        checkOnlyMe(onlyMe, atCounts) {
            let self = this;
            if (onlyMe && atCounts === 1) {
                self.atNoticeDialogVisible = true;
            } else {
                self.ajaxContent();
            }
        },
        hasSecondPerson(content) {
            let self = this;
            if (/([你您](?![们]))+/.test(content)) {
                self.withoutAtDialogVisible = true;
            } else {
                self.ajaxContent();
            }
        },
        isSecretWithNoAt(content) {
            // if (this.$privacy.find('span').text() === '私密') {
            //     shortTips("私密消息必须@别人才能收到哦！");
            //     return false;
            // } else {
                this.hasSecondPerson(content);
            // }
        },
        add(event) {
            let self=this;
            var content = this.$comment.val(),
                cutEmailContent = '',
                onlyMe = false,
                //if @ somebody
                hasAt = false,
                atCounts = 0;
            if (!$.trim(content)) {
                shortTips("评论不能为空哦");
                return false;
            }
            content.replace(this.defaults.atReg, function ($1, $2) {
                atCounts++;
                for (var j = 0; j < self.userList.length; j++) {
                    if ("@" + self.userList[j].nickname.toLowerCase() == $2.toLowerCase()) {
                        if (self.userList[j].id === self.defaults.userId) {
                            onlyMe = true;
                        }
                    }
                }
                return $1;
            });
            if (atCounts > 0) {
                cutEmailContent = content.replace(this.defaults.emailReg, '');
                hasAt = cutEmailContent ? (/\@/g.test(cutEmailContent) && true) : false;
                if (!hasAt) {
                    this.isSecretWithNoAt(content);
                } else {
                    var counts = cutEmailContent.match(this.defaults.atReg).length;
                    this.checkOnlyMe(onlyMe, counts);
                }
            } else {
                this.isSecretWithNoAt(content);
            }

            return false;
        },
        getAttachmentInfos() {
            var arr = [];
            let list = this.imagePreviewList.concat(this.attachmentPreviewList);
            list.forEach((v) => {
                arr.push({
                    bigPictureId: v.bigPicture && v.bigPicture.id || v.id || '',
                    thumbnailId: v.cropPicture && v.cropPicture.id || ''
                })
            })
            return arr;

        },
        resetImages() {
            this.$imagePreview.empty();
            this.imagePreviewCount = 0;
            this.imagePreviewList = [];
        },
        resetAttachment() {
            this.$attachmentPreview.empty();
            this.attachmentPreviewCount = 0;
            this.attachmentPreviewList = [];
        },
        resetCommentList() {
            this.resetImages();
            this.resetAttachment();
        },
        getMore(item, event) {
            this.defaults.start += this.defaults.defaultTake;
            this.getCommentList(this.commentObjectInfo.id);
        },
        refreshCommentList(postId, start, take, focus) {
            // if (this.xhr) {
            //     this.xhr.abort();
            // }
            this.comments = [];
            this.getCommentList(postId, start, take);
        },
        refreshAndGetCommentList(start, take, focus) {
            // if (this.xhr) {
            //     this.xhr.abort();
            // }
            // 保证刷新评论的时候保留原有的滚动位置。
            var scrollTop = $(window).scrollTop();
            if ($.isBoolean(start)) {
                focus = start;
                start = null;
                take = null;
            }
            if (focus === true) {
                $(document).one('comment.fetch.success', function (e) {
                    $(window).scrollTop(scrollTop);
                });
            }
            this.comments = [];
            this.getCommentList(this.commentObjectInfo.id,start, take);
        },
        // 判断是否是云端职位
        isCloud(list) {
            var self = this;
            function setCloudAttribute(comment) {
                if (comment.posterFirmId !== self.$store.state.candidateDetail.candidateInfo.candidateFirmId && comment.posterIdentityType === 2) {
                    comment.isCloud = true;
                }
            }
            if (!self.$store.state.candidateDetail.canSetCloud) return;
            if (!self.$store.state.candidateDetail.candidateInfo.candidateFirmId) return;
            if ($.isArray(list)) {
                // ko.utils.arrayForEach(list, setCloudAttribute);
                list.forEach((comment) => {
                    if (comment.posterFirmId !== self.$store.state.candidateDetail.candidateInfo.candidateFirmId && comment.posterIdentityType === 2) {
                        comment.isCloud = true;
                    }
                })
            } else {
                setCloudAttribute(list);
            }
        },
        // 判断是否是公开评论
        isPublic(list) {
            function setPublicAttribute(comment) {
                comment.isPublic = Date.parse(comment.created) > Date.parse(this.commentObjectInfo.publicizeTime);
            }

            if (!this.commentObjectInfo.publicizeTime) return;
            if ($.isArray(list)) {
                // ko.utils.arrayForEach(list, setPublicAttribute);
                list.forEach((comment) => {
                    comment.isPublic = Date.parse(comment.created) > Date.parse(this.commentObjectInfo.publicizeTime);
                })
            } else {
                setPublicAttribute(list);
            }
        },
        processItems(items) {
            items.forEach((v, k) => {
                this.processItem(v);
            })
        },
        processItem(item) {
            // 判断权限
            item.isShowOperation = this.isShowOperation || this.commentObjectInfo.isMyJob || this.commentObjectInfo.isProjectManagerOfJob;
            // item.isCreator = item.posterId === server.userId;
            item.isCreator = item.posterId === this.$store.state.user.userInfo.id;
        },
        getCommentList(postId,start, take) {
            let self = this;

            start = $.isNumeric(start) ? start : self.defaults.start;
            take = $.isNumeric(take) ? take : self.defaults.defaultTake;

            _request({
                url: '/' + self.defaults.commentType + '/' + postId + '/Comments?start=' + start + '&take=' + take,
                method: 'GET',
                throwBusinessError: true
            }).then(res => {
                if (res.total > 0) {
                    res.list.forEach((v, i) => {
                        v.attachmentList = v.fileAttachmentInfos;
                        v.imageList = v.pictureAttachmentUrls;
                        delete v.fileAttachmentInfos;
                        delete v.pictureAttachmentUrls;
                    })
                    self.processItems(res.list);
                    self.isCloud(res.list);
                    self.isPublic(res.list);
                    if (start == 0) {
                        self.comments = res.list;
                    } else {
                        self.comments = self.comments.concat(res.list);    
                    }
                    self.updateTotalComments(res.total);
                    self.viewLargeImage();
                    if (res.total > self.defaults.start + self.defaults.defaultTake) {
                        this.$more.removeClass("hide");
                    } else {
                        this.$more.addClass("hide");
                    }
                } else {
                    self.updateTotalComments(0);
                }
                $(document).trigger('comment.fetch.success', true, res);
            }).catch(err => {
                err = err || {};
                if (err.code == "NotJoinedFirm") {
                    self.updateTotalComments(err.total);
                } else {
                    shortTips(err.message || err.errorMessage || '系统异常，请稍后重试');
                }
            })
            // this.xhr = $.get(currentHost + '/' + self.defaults.commentType + '/' + postId + '/Comments?start=' + start + '&take=' + take, function (result, status, xhr) {
            // this.xhr = $.get('/' + self.defaults.commentType + '/' + postId + '/Comments?start=' + start + '&take=' + take, function (result, status, xhr) {
            //     var $more = self.$wrap.find(".more");
            //     if (result.succeeded) {
            //         if (result.data.total > 0) {
            //             result.data.list.forEach((v, i) => {
            //                 v.attachmentList = v.fileAttachmentInfos;
            //                 v.imageList = v.pictureAttachmentUrls;
            //                 delete v.fileAttachmentInfos;
            //                 delete v.pictureAttachmentUrls;
            //             })
            //             self.processItems(result.data.list);
            //             self.isCloud(result.data.list);
            //             self.isPublic(result.data.list);
            //             self.comments = self.comments.concat(result.data.list);
            //             self.updateTotalComments(result.data.total);
            //             self.viewLargeImage();
            //             if (result.data.total > self.defaults.start + self.defaults.defaultTake) {
            //                 $more.removeClass("hide");
            //             } else {
            //                 $more.addClass("hide");
            //             }
            //         } else {
            //             self.updateTotalComments(0);
            //         }
            //         $(document).trigger('comment.fetch.success', true, result.data);
            //     } else {
            //         result.data = result.data || {};

            //         if (result.data.code == "NotJoinedFirm") {
            //             self.updateTotalComments(result.data.total);
            //         } else {
            //             shortTips(result.message || result.errorMessage || '系统异常，请稍后重试');
            //         }
            //     }
            // });
        },
        formatHtml(str, arr) {
            return str.replace(/\[userId:'([^\]]+)*?'\]/g, function ($1, $2) {
                if (!arr) {
                    return $1;
                }
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].userId == $2) {
                        return "<span class='idCard text-primary lbd-cursor-pointer' data-id=" + arr[i].userId + ">@" + arr[i].nickname + "</span>";
                    }
                }
                return $1;
            });
        },
        onChangeSendEmail(vm) {
            if(vm.$data.active){
                this.isEmailNotic = true;
            }else{
                this.isEmailNotic = false;
            }
        }
    }
}
