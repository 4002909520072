import { resumeMerge as resumeMergeUrl } from '#/js/config/api.json';
import { resumeHide as resumeHideUrl, resumeSetting as resumeSettingUrl } from '#/js/config/javaApi.json';

export default {
    getMergeView(resumeId) {
        return _request({
            method: 'POST',
            url: resumeMergeUrl.get_merge_view,
            data: {
                resumeId: resumeId
            }
        })
    },
    doMerge(params) {
        return _request({
            method: 'POST',
            url: resumeMergeUrl.do_merge,
            data: params
        })
    },
    getMergeLog(params) {
        return _request({
            method: 'POST',
            url: resumeMergeUrl.merge_log,
            data: params
        })
    },
    getOperateLog(ossFileId) {
        return _request({
            method: 'GET',
            url: resumeMergeUrl.operateLog.replace('%p', ossFileId)
        })
    },
    getCandidateRedirectIds(ids) {
        return _request({
            method: 'POST',
            url: resumeMergeUrl.get_candidate_redirect_id,
            data: ids
        })
    },
    getHideResumeList(params = {}) {
        return _request({
            method: 'GET',
            url: resumeHideUrl.getHideResumeList,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    relieveHide(params) {
        return _request({
            method: 'POST',
            url: resumeHideUrl.relieveHide,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    hideResume(params, isUpdate = false) {
        return _request({
            method: 'POST',
            url: isUpdate ? resumeHideUrl.updateHideResume : resumeHideUrl.hideResume,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    getResumeSettings () {
        return _request({
            method: 'GET',
            url: resumeSettingUrl.get_firm_resume_settings,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
        })
    },
    saveResumeSettings (params) {
        return _request({
            method: 'POST',
            url: resumeSettingUrl.save_firm_resume_settings,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params
        })
    }
}
