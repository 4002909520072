var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.privacy == 0 ||
    (_vm.data.privacy == 1 &&
      (_vm.data.atMe || _vm.data.posterId == _vm.userId))
    ? _c(
        "li",
        _vm._b(
          { staticClass: "clearfix" },
          "li",
          { "data-commentid": _vm.data.id },
          false
        ),
        [
          _c(
            "div",
            { staticClass: "pull-left avatar" },
            [
              _c("avatar", {
                staticClass: "comment-item-avatar",
                attrs: {
                  src: _vm.data.avatarUrl,
                  guid: _vm.data.posterId,
                  size: "mg",
                  enableLink: false,
                },
              }),
              _vm.data.posterIdentityType === 1
                ? _c("div", { staticClass: "hr-tip btn-primary" }, [
                    _vm._v("HR"),
                  ])
                : _vm._e(),
              _vm.data.isCloud
                ? _c("div", { staticClass: "corner" }, [
                    _c("i", { staticClass: "fa fa-cloud-upload c-fc7859" }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "detail" }, [
            _c("h5", [
              _c(
                "span",
                _vm._b(
                  {
                    staticClass: "m-r-5 lbd-user-card",
                    domProps: { textContent: _vm._s(_vm.data.posterName) },
                  },
                  "span",
                  { "data-id": _vm.data.posterId },
                  false
                )
              ),
              _c("span", {
                staticClass: "m-r-5 nickname",
                attrs: { type: "nickname" },
                domProps: {
                  textContent: _vm._s("@" + _vm.data.posterNickname),
                },
              }),
              _c("span", {
                staticClass: "m-r-5",
                domProps: { textContent: _vm._s(_vm.data.posterFirmShortName) },
              }),
              _c("span", {
                staticClass: "m-r-20",
                domProps: { textContent: _vm._s(_vm.data.posterTitle) },
              }),
              _c("span", { staticClass: "text-light pull-right" }, [
                _vm._v(_vm._s(_vm._f("datetime")(_vm.data.created))),
              ]),
            ]),
            _vm.data.privacy == 1
              ? _c(
                  "span",
                  { staticClass: "secret", attrs: { title: "私密" } },
                  [_vm._v("密")]
                )
              : _vm._e(),
            _vm.data.hasSticky
              ? _c(
                  "span",
                  { staticClass: "sticky-top", attrs: { title: "顶" } },
                  [_vm._v("顶")]
                )
              : _vm._e(),
            _vm.data.privacy == 0
              ? _c(
                  "span",
                  { staticClass: "secret hide", attrs: { title: "私密" } },
                  [_vm._v("密")]
                )
              : _vm._e(),
            _c("span", {
              staticClass: "detail-content",
              domProps: {
                innerHTML: _vm._s(
                  _vm.formatHtml(_vm.data.content, _vm.data.atUsers)
                ),
              },
            }),
            _vm.data.imageList
              ? _c(
                  "div",
                  { staticClass: "comment-image image-list clearfix" },
                  _vm._l(_vm.data.imageList, function (image) {
                    return _c(
                      "div",
                      _vm._b(
                        { staticClass: "image-item" },
                        "div",
                        { href: image.bigPictureUrl },
                        false
                      ),
                      [
                        _c(
                          "img",
                          _vm._b({}, "img", { src: image.thumbnailUrl }, false)
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.data.attachmentList
              ? _c(
                  "div",
                  { staticClass: "attachment-list clearfix" },
                  _vm._l(_vm.data.attachmentList, function (attachment) {
                    return _c(
                      "div",
                      _vm._b(
                        { staticClass: "attachment-item" },
                        "div",
                        { href: attachment.fileUrl },
                        false
                      ),
                      [
                        _c("i", {
                          staticClass: "fa fa-paperclip text-primary",
                        }),
                        _c(
                          "a",
                          _vm._b(
                            {
                              staticClass: "text-light",
                              attrs: { target: "_blank", title: "下载附件" },
                              domProps: {
                                textContent: _vm._s(
                                  attachment.name || "无文件名"
                                ),
                              },
                            },
                            "a",
                            { href: attachment.fileUrl },
                            false
                          )
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "btn-stage text-light" },
              [
                _vm.data.isShowOperation
                  ? [
                      _vm.data.hasSticky
                        ? _c("span", { staticClass: "is-top invisible" }, [
                            _vm._m(0),
                          ])
                        : _vm._e(),
                      !_vm.data.hasSticky
                        ? _c("span", { staticClass: "not-top invisible" }, [
                            _vm._m(1),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.data.isCreator
                  ? [
                      _vm.data.privacy == 1
                        ? _c("span", { staticClass: "public invisible" }, [
                            _vm._m(2),
                          ])
                        : _vm._e(),
                      _vm.data.privacy == 0
                        ? _c("span", { staticClass: "encrypt invisible" }, [
                            _vm._m(3),
                          ])
                        : _vm._e(),
                      _vm._m(4),
                    ]
                  : _vm._e(),
                _vm._m(5),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "fa fa-arrow-down" }, [
      _c("span", [_vm._v("取消置顶")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "fa fa-arrow-up" }, [
      _c("span", [_vm._v("置顶")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "fa fa-eye" }, [_c("span", [_vm._v("公开")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "fa fa-lock" }, [
      _c("span", [_vm._v("私密")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "delete invisible" }, [
      _c("i", { staticClass: "fa fa-remove" }, [_c("span", [_vm._v("删除")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "reply" }, [
      _c("i", { staticClass: "fa fa-reply" }, [_c("span", [_vm._v("回复")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }