<template>
    <div>
        <div class="comment-wrapper">
            <div class="comments-wrap">
                <div id="commentPanelId" class="coment-wrap">
                    <!-- <h2 class="title">
                        <span class="title-text"><span v-text="commentTitle"></span><span>(<span v-text="commentCount"></span>)</span></span>
                    </h2> -->
                    <form class="form-horizontal" v-show="!commentObjectInfo.isSleeping" @submit.prevent="add" method="post" name="comment">
                        <div class="form-group">
                            <div class="atWrap">
                                <textarea rows="4" id="comment" data-haha="123" v-bind="{'data-isMyJob': commentObjectInfo.isMyJob ? 1 : 0}" name="comment" placeholder="" data-maxLength=""></textarea>
                                <div class="at-tools clearfix">
                                    <!-- 公开/私密 -->
                                    <div class="pull-right">
                                        <input type="hidden" name="privacy" :value="privacy" />
                                        <el-dropdown @command="handlePrivacyCommand">
                                            <span class="el-dropdown-link">
                                                <!-- 下拉菜单<i class="el-icon-arrow-down el-icon--right"></i> -->
                                                <a v-if="privacy == 0" id="privacy" class="privacy" title="设置私密性" href="javascript:;"><font-icon href="#icon-chakan"></font-icon><span>公开</span></a>
                                                <a v-if="privacy == 1" id="privacy" class="privacy" title="设置私密性" href="javascript:;"><font-icon href="#icon-suo1"></font-icon><span>私密</span></a>
                                            </span>
                                            <el-dropdown-menu slot="dropdown" class="privacy-dropdown-menu">
                                                <el-tooltip class="item" effect="light" content="所有人能看到评论" placement="left">
                                                    <el-dropdown-item command="0" :disabled="disablePrivacy">
                                                        <font-icon href="#icon-chakan"></font-icon>公开
                                                    </el-dropdown-item>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="light" content="只有被@的人才能看到评论" placement="left">
                                                    <el-dropdown-item command="1">
                                                        <font-icon href="#icon-suo1"></font-icon>私密
                                                    </el-dropdown-item>
                                                </el-tooltip>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <!-- <ul class="dropdown-menu" aria-labelledby="privacy">
                                            <li><a data-value="0" href="javascript:void(0);" role="button" data-toggle="popover" data-trigger="hover"><i class="fa fa-eye"></i>公开</a></li>
                                            <li><a data-value="1" href="javascript:void(0);" role="button" data-toggle="popover" data-trigger="hover"><i class="fa fa-lock"></i>私密</a></li>
                                        </ul>
                                        <input type="hidden" name="privacy" value="0" /> -->
                                    </div>
                                    <!-- 附件 -->
                                    <div class="pull-right comment-image">
                                        <!-- <a href="javascript:;"><i class="fa fa-paperclip"></i></a> -->
                                        <a href="javascript:;"><font-icon href="#icon-fujian"></font-icon></a>
                                        <input id="commentWithImageId" title="添加附件" class="add-image" type="file" name="file" value="0" />
                                    </div>
                                    <!-- @艾特 -->
                                    <div class="pull-right">
                                        <input type="hidden" name="atUserIds" />
                                        <el-popover
                                            placement="bottom"
                                            title=""
                                            width="370"
                                            trigger="click"
                                            v-model="atListPopoverVisible"
                                        >
                                            <div class="atList" aria-labelledby="atlist">
                                                <div class="search">
                                                    <input @keyup="searchUsers" @keydown="stopEvent" autocomplete="off" type="text" name="keyword" placeholder="查找更多好友" />
                                                </div>
                                                <ul>
                                                    <template v-for="(user, index) in users">
                                                        <p v-if="user.type == 1 && (index == (users.length-2))" class="bazaSelectMenuTipsBox">小猎或其他用户</p>
                                                        <li>
                                                            <a @click="selectPerson(user, $event)" href="javascript:;">
                                                                <img v-bind:src="user.avatar | avatar" width="16" /> 
                                                                <span class="team-tip" v-if="user.isFirmTeam">团队</span>
                                                                <span class="realName" v-text="user.realName"></span>
                                                                <span class="nickname">{{ user.nickname ? `@${user.nickname}` : '' }}</span>
                                                            </a>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                            <a slot="reference" class="at-list-icon" id="atlist" title="对TA说" href="javascript:;" @click="getClickAtUserInfo">@</a>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comment-image preview clearfix">
                            <div class="image-item" v-for="imagePreview in imagePreviewList">
                                <img v-bind="{src: imagePreview.cropPicture.url, 'data-thumbnailid': imagePreview.cropPicture.id, 'data-bigpictureid': imagePreview.bigPicture.id}">
                                <i class="fa fa-close delete"></i>
                                <!-- <font-icon class="fa fa-close delete" href="#icon-ic_off_tj1"></font-icon> -->
                            </div>
                        </div>
                        <div class="comment-attachment preview clearfix">
                            <div class="attachment-item" v-for="(attachmentPreview, idx) in attachmentPreviewList" :key="'att_' + idx">
                                <!-- <i class="fa fa-paperclip text-primary"></i> -->
                                <span>
                                    <i class="el-icon-paperclip"></i>
                                    <a class="text-light" target="_blank" title="下载附件" v-text="attachmentPreview.name || 'xxx'"></a>
                                </span>
                                <!-- <i class="fa fa-close delete"></i> -->
                                <font-icon class="fa fa-close delete" href="#icon-ic_off_tj1"></font-icon>
                            </div>
                        </div>
                        <div class="comment-operation">
                            <div class="col-xs-6 email-operation">
                                <span id="error" class="text-danger hide"></span>
                                <p v-show="isEmail" class="email-checkbox">
                                    <!--<checkbox k-checked="isEmailNotice" enableTrue="true" name="isEmailNotice" label="发送邮件通知"></checkbox>-->
                                    <!-- <checkbox :checked="isEmail" name="isEmailNotic" @on-click="onChangeSendEmail"></checkbox> -->
                                    <el-checkbox v-model="isEmailNotic">发送邮件通知</el-checkbox>
                                    <!-- <label class="email-label">发送邮件通知</label> -->
                                </p>
                            </div>
                            <div class="col-xs-6 text-right">
                                <input class="btn btn-primary btn-background" id="publish" type="submit" value="发表" v-bind="{ disabled: !canPost }" />
                            </div>
                        </div>
                    </form>
                    <div class="comments row row-overflow">
                        <div class="comment-list">
                            <ul>
                                <li
                                    class="clearfix"
                                    v-for="(item, index) in comments"
                                    :key="index"
                                    v-show="item.privacy == 0 || (item.privacy == 1 && (item.atMe || item.posterId == userId))"
                                >
                                    <div class="clearfix commentid" :data-commentid="item.id">
                                        <div class="pull-left avatar">
                                            <avatar
                                                size="md"
                                                :src="item.avatarUrl"
                                                :userId="item.posterId"
                                                :enableCard="true"
                                                :enableLink="true"
                                                :shareData="shareData"
                                            ></avatar>
                                            <div v-if="item.posterIdentityType === 1" class="hr-tip btn-primary">HR</div>
                                            <!-- <div v-if="item.isCloud" class="corner">
                                                <i class="fa fa-cloud-upload c-fc7859"></i>
                                            </div> -->
                                        </div>
                                        <div class="detail">
                                            <h5>
                                                <span v-text="item.posterName" v-bind="{'data-id': item.posterId}" class="m-r-5 lbd-user-card"></span>
                                                <span v-text="'@'+ item.posterNickname" class="m-r-5 nickname" type="nickname"></span>
                                                <!-- <span v-text="item.posterFirmShortName" class="m-r-5"></span>
                                                <span v-text="item.posterTitle" class="m-r-20"></span> -->
                                                <!-- <span v-if="item.isCloud" class="pull-right text-light m-l-10 c-fc7859">来自云端分享</span> -->
                                                <span class="text-light pull-right">{{ item.created | datetime }}</span>
                                            </h5>
                                            <span v-if="item.privacy == 1" class="secret" title="私密">密</span>
                                            <span v-if="item.hasSticky" class="sticky-top" title="顶">顶</span>
                                            <span v-if="item.privacy == 0" class="secret hide" title="私密">密</span>
                                            <span v-html="formatHtml(item.content, item.atUsers)" class="detail-content"></span>
                                            <div v-if="item.imageList" class="comment-image image-list clearfix">
                                                <div v-for="image in item.imageList" class="image-item" v-bind="{href: image.bigPictureUrl}"><img v-bind="{src: image.thumbnailUrl}"></div>
                                            </div>
                                            <div v-if="item.attachmentList" class="attachment-list clearfix">
                                                <div v-for="attachment in item.attachmentList" class="attachment-item" v-bind="{href: attachment.fileUrl}">
                                                    <i class="fa fa-paperclip text-primary"></i>
                                                    <a class="text-light" target="_blank" title="下载附件" v-bind="{href : attachment.fileUrl}" v-text="attachment.name || '无文件名'"></a>
                                                </div>
                                            </div>
                                            <div class="btn-stage text-light">
                                                <div class="option">
                                                    <span class="reply"><font-icon href="#icon-xinliweibao-pinglun"></font-icon>回复</span>
                                                    <template v-if="item.isCreator">
                                                        <span class="delete invisible"><font-icon href="#icon-shanchu"></font-icon>删除</span>
                                                    </template>
                                                    <template v-if="item.isShowOperation">
                                                        <span v-if="item.hasSticky" class="is-top invisible"><font-icon href="#icon-zhiding"></font-icon>取消置顶</span>
                                                        <span v-if="!item.hasSticky" class="not-top invisible"><font-icon href="#icon-zhiding"></font-icon>置顶</span>
                                                    </template>
                                                </div>
                                                <div class="privacy">
                                                    <template v-if="item.isCreator">
                                                        <span v-if="item.privacy == 1" class="public invisible"><font-icon href="#icon-chakan"></font-icon>公开</span>
                                                        <span v-if="item.privacy == 0" class="encrypt invisible"><font-icon href="#icon-suo1"></font-icon>私密</span>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <divider v-show="hasInternalComment" text="以下评论仅我公司成员可见"></divider>
                            <ul v-show="hasInternalComment">
                                <comment-item :data="item" v-for="(item, index) in getInternalComments" :key="index"></comment-item>
                            </ul>
                            <a class="more hide" @click="getMore" href="javascript:;">加载更多 <i class="fa fa-angle-double-down"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            title="删除评论"
            :visible.sync="deleteDialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <span>确定删除这条评论吗</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDelete">取消</el-button>
                <el-button v-loading="confirmDeleteLoading" type="primary" @click="confirmDelete">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="提示"
            :visible.sync="atNoticeDialogVisible"
            width="500px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <span>你只@了自己，只有自己才能收到哦！</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelAtNotice">取消</el-button>
                <el-button v-loading="confirmAtNoticeLoading" type="primary" @click="confirmAtNotice">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="确定发表吗？"
            :visible.sync="withoutAtDialogVisible"
            width="320px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
        >
            <span>你好像想对别人留言，但没有@任何人。<br/>@了别人才能收到消息哦！</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelWithoutAt">取消</el-button>
                <el-button v-loading="confirmWithoutAtLoading" type="primary" @click="confirmWithoutAt">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import './widget.js';
    import './jquery.baza.atSelect-vue.js';
    import Avatar from '#/component/common/avatar.vue';
    import Divider from '#/component/common/divider.vue';
    import CommentItem from './commentItem';
    import atSystemMixin from './atSystem-vue';

    export default {
        components:{
            Avatar,
            Divider,
            CommentItem,
        },
        mixins: [
            atSystemMixin
        ],
        computed:{
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            },
            userId() {
                return this.$store.state.user.userInfo.id;
            }
        },
        data() {
            return {
                commentType: this.$attrs.commentType || '',
                placeholderText: this.$attrs.placeholderText || '',
                atsystem: null,
                isEmailNotic:this.isEmail,
                isShowOperation: false,
                deleteDialogVisible: false,
                deleteCommentId: '',
                confirmDeleteLoading: false,
                atNoticeDialogVisible: false,
                confirmAtNoticeLoading: false,
                withoutAtDialogVisible: false,
                confirmWithoutAtLoading: false,
                privacy: 0,
                atListPopoverVisible: false,
                disablePrivacy: false,
            }
        },
        props: {
            commentTitle:{
                type: String,
                default: '评论'
            },
            prefetch: Boolean,
            canPost: {
                type: Boolean,
                default: true
            },
            team: Boolean,
            isEmail: Boolean,
            commentObjectInfo: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            shareData: Object,
            maxLimit: {
                type: Number,
                default: 0
            }
        },
        watch: {
            commentObjectInfo: function(val) {
                this.refreshNodes();
                this.$comment.data('AtSelect').options.isMyJob = this.commentObjectInfo.isMyJob;
                console.log(this.$comment.data('AtSelect').options);
            }
        },
        mounted () {
        },
        methods: {
            // refresh(){
            //     this.refresh();
            // },
            setCommentType(commentType){
                this.commentType = commentType;
            },
            setPlaceHolderText(placeholderText){
                this.placeholderText = placeholderText;
            },
            atAndTip(id){
                $(window).scrollTop(this.$comment.offset().top);
                this.$comment.val('@' + id + ' ');
                this.$comment.data('AtSelect').lookup();
            },
            formatHtml(str, arr) {
                return str.replace(/\[userId:'([^\]]+)*?'\]/g, function ($1, $2) {
                    if (!arr) {
                        return $1;
                    }
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i].userId == $2) {
                            return "<span class='idCard text-primary lbd-cursor-pointer' data-id=" + arr[i].userId + ">@" + arr[i].nickname + "</span>";
                        }
                    }
                    return $1;
                });
            },
            cancelDelete() {
                this.deleteDialogVisible = false;
            },
            confirmDelete() {
                if(this.confirmDeleteLoading) {
                    return false;
                }
                this.confirmDeleteLoading = true;
                _request({
                    url: '/Communication/Delete',
                    method: 'POST',
                    data: {
                        commentId: this.deleteCommentId
                    }
                }).then(res => {
                    this.refresh();
                    shortTips('评论删除成功');
                    this.deleteDialogVisible = false;
                }).finally(() => {
                    this.confirmDeleteLoading = false;
                })
            },
            cancelAtNotice() {
                this.atNoticeDialogVisible = false;
            },
            confirmAtNotice() {
                if(this.confirmAtNoticeLoading) {
                    return false;
                }
                this.confirmAtNoticeLoading = true;
                this.ajaxContent(() => {
                    this.atNoticeDialogVisible = false;
                });
            },
            cancelWithoutAt() {
                this.withoutAtDialogVisible = false;
            },
            confirmWithoutAt() {
                if(this.confirmWithoutAtLoading) {
                    return false;
                }
                this.confirmWithoutAtLoading = true;
                this.ajaxContent(() => {
                    this.withoutAtDialogVisible = false;
                });
            },
            handlePrivacyCommand(val) {
                this.privacy = val;
            }
        }
    }
</script>

<style lang="scss">
// @import '../../../scss/foundation/_config';
// @import '../../../scss/mixin/_clearfix';
// @import '../../../scss/placeholder/_component';
// @import "../../../scss/library/bootstrap/custom";
// @import "../../../scss/library/bootstrap/mixins";
// @import '../../../scss/library/bootstrap/dropdown';
@import './jquery.baza.atSelect.scss';

.coment-wrap{
    .row-overflow{
        margin-left: -15px;
        margin-right: -15px;
        .email-checkbox{
            display: flex;
            align-items: center;
            .email-label{
                margin-bottom: 0;
                margin-left: 10px
            }
        }
    }
    .detail-content{
        word-wrap: break-word;
    }
    ul{
        margin: 0;
        padding: 0;
    }

    .pull-right > .dropdown-menu {
        right: 0;
        left: auto;
    }
}
.coment-wrap .comments .avatar{
    border-radius: 50% !important;
    // width: 60px;
    // height: 60px;
    width: 32px;
    height: 32px;
}
.coment-wrap {
    padding-top: 0;
    padding-bottom: 40px;
    .title{
        margin-bottom: 10px;
        padding: 10px 0;
        margin-top: 50px;
        &:before {
            content: " ";
            display: inline-block;
            border-left: 8px solid $primary;
            height: 20px;
            margin-right: 5px;
            vertical-align: middle;
        }
        .job-detail-title,.title-text{
            font-size: 18px;
            vertical-align: middle;
        }

        .title-link {
            font-size: 16px;
            line-height: 32px;
            text-align: right;
            float: right;
        }
    }
}

.coment-wrap .fa:before,
.coment-wrap .fa span {
    vertical-align: middle;
}

.coment-wrap .fa span {
    font-family: "微软雅黑", Arial;
}

.coment-wrap .btn-primary {
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 5px;
    min-width: 90px;
    height: 34px;
}
.coment-wrap .btn-background{
    background-color: #38bc9c;
    &:hover{
        border: none;
    }
}

.coment-wrap .comments li {
    padding: 20px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ececec;
    list-style: none;
}

.coment-wrap .comments li:last-child {
    border-bottom: 0 none;
}

.coment-wrap .comments h5 {
    padding: 0;
    margin: 0 0 5px;
    color: 10px;
    font-size: 14px;
}

.coment-wrap .comments  .detail .lbd-user-card {
    color: #333;
}

.coment-wrap .comments  .detail .nickname {
    color: #b9b9b9;
    font-size: 12px;
}

.coment-wrap .comments h5 .text-light {
    font-size: 14px;
    color: #999999;
}

.coment-wrap .comments h5 .title {
    font-size: 14px;
}

.coment-wrap .comments {
    // margin-top: 50px;
    position: relative;

    .comment-list {
        padding: 0 15px;
    }
}

.coment-wrap .comments p {
    margin: 0;
    line-height: 22px;
}

.coment-wrap .comments li .detail {
    // margin-left: 70px;
    margin-left: 40px;
    // margin-top: 5px;
    margin-top: 9px;
    word-wrap: break-word;
}

.coment-wrap .comments .secret,
.coment-wrap .comments .sticky-top {
    color: #FF4B4B;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #FFA8A8;
    padding: 1px 3px;
    background-color: #FFECEC;
    margin-right: 4px;
}

.coment-wrap .comments .pull-left {
    position: relative;
    border-radius: 0;
    overflow: visible;
}

.coment-wrap .comments .hr-tip {
    position: absolute;
    right: -2px;
    bottom: 4px;
    padding: 1px 3px;
    font-size: 12px;
    font-weight: 100;
    background-color: rgba(94, 207, 186, 0.7);
    line-height: 9px;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
}

.coment-wrap .comments .btn-stage {
    margin-top: 6px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    span {
        cursor: pointer;
        margin-right: 22px;
        color: #999999;
        display: inline-flex;
        align-items: center;
        &:last-child {
            margin-right: 0;
        }
        .icon {
            margin-right: 6px;
            font-size: 16px;
            color: #999999;
        }
        &:hover {
            color: #5ecfba;
            .icon {
                color: #5ecfba;
            }
        }
    }
    .privacy {
        span {
            color: #666666;
            .icon {
                color: #333333;
            }
            &:hover {
                color: $primary;
                .icon {
                    color: $primary;
                }
            }
        }
    }
}
.coment-wrap .comments .detail:hover .invisible {
    visibility: visible !important;
}

.coment-wrap .more {
    display: block;
    text-align: center;
    background: #f0f1f3;
    color: #999;
    line-height: 40px;
    margin-top: 15px;
}

.coment-wrap .more:hover,
.coment-wrap .more:focus,
.coment-wrap .more:active {
    background: #e5e6e7;
    text-decoration: none;
}

.privacy-dropdown-menu {
    li {
        display: flex;
        align-items: center;
        .icon {
            font-size: 16px;
            margin-right: 6px;
        }
    }
}
.atWrap {
    position: relative;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    
    textarea {
        border: 0 none;
        font-family: Microsoft YaHei, Arial;
        font-size: 14px;
        height: auto;
        padding: 10px;
        width: 100%;
        resize: none;
    }

    .at-tools {
        padding: 5px 20px;
        margin: 0;
        position: relative;
        background-color: #fff;
        border-radius: 5px;

        .privacy {
            width: 55px;
            margin-left: 15px;
            color: #666666;
            .icon {
                font-size: 18px;
                color: #000;
                margin-right: 6px;
            }
            span {
                vertical-align: middle;
            }
            &:hover {
                color: $primary;
                .icon {
                    color: $primary;
                }
            }
        }
        /*评论图片*/
        .comment-image {
            cursor: pointer;
            position: relative;
            overflow: hidden;

            a {
                .icon {
                    font-size: 18px;
                    color: #000;
                }
                &:hover {
                    .icon {
                        fill: $primary;
                    }
                }
            }
        }
    }
}
.atWrap .at-tools .fa:hover,
.atWrap .at-tools .pull-right>a:hover,
.atWrap .at-tools .pull-right .at-list-icon:hover,
.atWrap .at-tools .comment-image:hover .fa,
.atWrap .dropdown-menu li:hover a, .atList li:hover a {
    color: $primary;
}

.atWrap .at-tools .pull-right>a, .atWrap .at-tools .pull-right .at-list-icon {
    color: #ccc;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    margin-left: 15px;
    vertical-align: middle;
    width: 18px;
}

.atWrap .at-tools .pull-right .at-list-icon {
    // color: #a3afb7;
    color: #333;
    text-decoration: none;
    font-weight: 600;

    &:focus {
        border: none;
    }
}

.atWrap .at-tools .pull-right>a:hover,
.atWrap .at-tools .pull-right>a:focus,
.atWrap .at-tools .pull-right>a:active {
    text-decoration: none;
}

.atWrap .at-tools .comment-image .add-image {
    position: absolute;
    top: 4px;
    left: 15px;
    width: 18px;
    height: 18px;
    opacity: 0;
    filter: alpha(opacity=0);
    line-height: 18px;
    font-size: 0;
    cursor: pointer;
}

.comment-image .image-item {
    width: 90px;
    height: 90px;
    float: left;
    margin-top: 10px;
    margin-right: 10px;
    border: 1px solid #ececec;
    cursor: pointer;
}

.comment-image.preview .image-item {
    position: relative;
    width: 50px;
    height: 50px;
    margin-top: 0;
    // margin-bottom: 15px;
    margin-bottom: 5px;
}

.comment-image.preview .image-item .delete {
    position: absolute;
    display: none;
    top: 2px;
    right: 2px;
    color: #ff7a7a;
}

.comment-image.preview .image-item:hover .delete {
    display: block;
}

.comment-image.preview .image-item .delete:hover {
    color: #ff493c;
}

.comment-image .image-item img {
    width: 100%;
    height: 100%;
}

.atWrap {
    min-width: 120px;
}

.atList {
    min-width: 240px;
}

.atList .search {
    margin: 0;
}

.atList input {
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    border: 1px solid #e6e6e6;
    margin: 10px;
    width: 90%;
}

.atWrap .dropdown-menu ul, .atList ul{
    max-height: 280px;
    overflow-y: auto;
}

.atWrap .dropdown-menu li a, .atList li a {
    clear: both;
    color: #828282;
    display: block;
    font-weight: normal;
    line-height: 1.42857;
    padding: 5px 20px;
    white-space: nowrap;
}

.atList li a {
    overflow: hidden;
    text-overflow: ellipsis;
}

.atWrap .dropdown-menu li a .fa {
    display: inline-block;
    width: 18px;
    margin-right: 10px;
}

.atWrap .dropdown-menu li a .fa-lock {
    font-size: 15px;
}

.team-tip {
    margin: 0 2px;
    font-size: 12px;
    color: #3dbaa3;
    line-height: 20px;
}
.bazaSelectMenu .team-tip {
    display: none;
}

.bazaSelectMenu .realName {
    color: #666;
}

.bazaSelectMenu .nickname {
    color: #999;
}

.atList li a:hover,
.atList li a:focus {
    text-decoration: none;
    background-color: #eef6f9;
}

.comment-container .popover {
    min-width: 220px;
}

.comment-container .btn-disabled {
    width: 92px;
}

.attachment-list .attachment-item {
    display: inline-block;
    padding: 10px 10px 0 0;
    float: left;
}

.comment-operation .email-operation {
    float: left;
}

.comment-attachment.preview .attachment-item {
    // display: inline-block;
    // padding: 0 10px 0;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #F6F6F6;
    border-radius: 5px;
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-icon-paperclip {
        color: #38BC9D;
        font-size: 16px;
        vertical-align: middle;
    }
    a {
        color: #38BC9D;
    }
}

.comment-attachment.preview a:hover,
.comment-attachment.preview a:visited,
.comment-attachment.preview a:focus {
    outline: none;
    text-decoration: none;
}

.comment-attachment.preview .attachment-item .delete:before {
    color: #999;
    font-size: 12px;
    line-height: inherit;
    vertical-align: baseline;
}

.comment-attachment.preview .attachment-item .delete {
    margin-left: 10px;
}

.comment-attachment.preview .attachment-item .delete:hover:before {
    color: #3dbaa3;
}

// .comment-attachment.preview .attachment-item:hover a,
.comment-attachment.preview .attachment-item:hover .delete:before {
    color: #3dbaa3;
}

input[type=file]::-webkit-file-upload-button {
    cursor: pointer;
}

// .comments .avatar {
//     width: 50px;
//     height: 50px;
// }

.comments .avatar .corner {
    position: absolute;
    top: -2px;
    right: -7px;
    width: 20px;
    height: 20px;
    z-index: 222;
    color: #fff;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    line-height: 18px;
}

.comments-wrap .form-horizontal .form-group {
    margin: 0 0 20px 0;
}

.text-right {
    text-align: right;
}

.btn-stage .fa:hover {
    color: $primary;
}

.delete-comment-dialog {
    padding-top: 20px;

    .modal-box-caption {
        font-size: 18px;
        color: #333;
    }
}

.bazaLoding {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
.bazaLoding i {
    position: absolute;
    top: 49%;
    left: 45%;
    color: #000;
    font-size: 30px;
}

.modal-dialog .alert-info {
    padding: 0;
}
.modal-dialog .fa-info-circle {
    font-size: 54px;
    color: #3399ff;
    margin-left: 10px;
}

.modal-dialog .sub-title {
    font-size: 18px;
    color: #3399ff;
    line-height: 30px;
}

.comment-operation {
    .email-operation {
        .el-checkbox {
            .el-checkbox__inner {
                border-radius: 4px;
            }
            .el-checkbox__label {
                color: #999999;
                padding-left: 6px;
            }
        }
    }
}
</style>
