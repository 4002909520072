<template>
    <li v-if="data.privacy == 0 || (data.privacy == 1 && (data.atMe || data.posterId == userId))" class="clearfix" v-bind="{'data-commentid': data.id}">
        <div class="pull-left avatar">
            <avatar class="comment-item-avatar" :src="data.avatarUrl" :guid="data.posterId" size="mg" :enableLink="false"></avatar>
            <div v-if="data.posterIdentityType === 1" class="hr-tip btn-primary">HR</div>
            <div v-if="data.isCloud" class="corner">
                <i class="fa fa-cloud-upload c-fc7859"></i>
            </div>
        </div>
        <div class="detail">
            <h5>
                <span v-text="data.posterName" v-bind="{'data-id': data.posterId}" class="m-r-5 lbd-user-card"></span>
                <span v-text="'@'+ data.posterNickname" class="m-r-5 nickname" type="nickname"></span>
                <span v-text="data.posterFirmShortName" class="m-r-5"></span>
                <span v-text="data.posterTitle" class="m-r-20"></span>
                <!-- <span v-if="data.isCloud" class="pull-right text-light m-l-10 c-fc7859">来自云端分享</span> -->
                <span class="text-light pull-right">{{ data.created | datetime }}</span>
            </h5>
            <span v-if="data.privacy == 1" class="secret" title="私密">密</span>
            <span v-if="data.hasSticky" class="sticky-top" title="顶">顶</span>
            <span v-if="data.privacy == 0" class="secret hide" title="私密">密</span>
            <span v-html="formatHtml(data.content, data.atUsers)" class="detail-content"></span>
            <div v-if="data.imageList" class="comment-image image-list clearfix">
                <div v-for="image in data.imageList" class="image-item" v-bind="{href: image.bigPictureUrl}"><img v-bind="{src: image.thumbnailUrl}"></div>
            </div>
            <div v-if="data.attachmentList" class="attachment-list clearfix">
                <div v-for="attachment in data.attachmentList" class="attachment-item" v-bind="{href: attachment.fileUrl}">
                    <i class="fa fa-paperclip text-primary"></i>
                    <a class="text-light" target="_blank" title="下载附件" v-bind="{href : attachment.fileUrl}" v-text="attachment.name || '无文件名'"></a>
                </div>
            </div>
            <div class="btn-stage text-light">
                <template v-if="data.isShowOperation">
                    <span v-if="data.hasSticky" class="is-top invisible"><i class="fa fa-arrow-down"><span>取消置顶</span></i></span>
                    <span v-if="!data.hasSticky" class="not-top invisible"><i class="fa fa-arrow-up"><span>置顶</span></i></span>
                </template>
                <template v-if="data.isCreator">
                    <span v-if="data.privacy == 1" class="public invisible"><i class="fa fa-eye"><span>公开</span></i></span>
                    <span v-if="data.privacy == 0" class="encrypt invisible"><i class="fa fa-lock"><span>私密</span></i></span>
                    <span class="delete invisible"><i class="fa fa-remove"><span>删除</span></i></span>
                </template>
                <span class="reply"><i class="fa fa-reply"><span>回复</span></i></span>
            </div>
        </div>
    </li>
</template>

<script>
    import Avatar from '#/component/common/avatar.vue';
    export default {
        name: "commentItem",
        components: {
            Avatar
        },
        props: {
            data: null
        },
        computed: {
            userId() {
                return this.$store.state.user.userInfo.id;
            }
        },
        methods: {
            formatHtml(str, arr) {
                return str.replace(/\[userId:'([^\]]+)*?'\]/g, function ($1, $2) {
                    if (!arr) {
                        return $1;
                    }
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i].userId == $2) {
                            return "<span class='idCard text-primary lbd-cursor-pointer' data-id=" + arr[i].userId + ">@" + arr[i].nickname + "</span>";
                        }
                    }
                    return $1;
                });
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
