var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "comment-wrapper" }, [
        _c("div", { staticClass: "comments-wrap" }, [
          _c(
            "div",
            { staticClass: "coment-wrap", attrs: { id: "commentPanelId" } },
            [
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.commentObjectInfo.isSleeping,
                      expression: "!commentObjectInfo.isSleeping",
                    },
                  ],
                  staticClass: "form-horizontal",
                  attrs: { method: "post", name: "comment" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.add.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "atWrap" }, [
                      _c(
                        "textarea",
                        _vm._b(
                          {
                            attrs: {
                              rows: "4",
                              id: "comment",
                              "data-haha": "123",
                              name: "comment",
                              placeholder: "",
                              "data-maxLength": "",
                            },
                          },
                          "textarea",
                          {
                            "data-isMyJob": _vm.commentObjectInfo.isMyJob
                              ? 1
                              : 0,
                          },
                          false
                        )
                      ),
                      _c("div", { staticClass: "at-tools clearfix" }, [
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("input", {
                              attrs: { type: "hidden", name: "privacy" },
                              domProps: { value: _vm.privacy },
                            }),
                            _c(
                              "el-dropdown",
                              { on: { command: _vm.handlePrivacyCommand } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm.privacy == 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "privacy",
                                            attrs: {
                                              id: "privacy",
                                              title: "设置私密性",
                                              href: "javascript:;",
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-chakan" },
                                            }),
                                            _c("span", [_vm._v("公开")]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.privacy == 1
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "privacy",
                                            attrs: {
                                              id: "privacy",
                                              title: "设置私密性",
                                              href: "javascript:;",
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-suo1" },
                                            }),
                                            _c("span", [_vm._v("私密")]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    staticClass: "privacy-dropdown-menu",
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "light",
                                          content: "所有人能看到评论",
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: "0",
                                              disabled: _vm.disablePrivacy,
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-chakan" },
                                            }),
                                            _vm._v(
                                              "公开\n                                                "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "light",
                                          content: "只有被@的人才能看到评论",
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "1" } },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-suo1" },
                                            }),
                                            _vm._v(
                                              "私密\n                                                "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "pull-right comment-image" }, [
                          _c(
                            "a",
                            { attrs: { href: "javascript:;" } },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-fujian" },
                              }),
                            ],
                            1
                          ),
                          _c("input", {
                            staticClass: "add-image",
                            attrs: {
                              id: "commentWithImageId",
                              title: "添加附件",
                              type: "file",
                              name: "file",
                              value: "0",
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("input", {
                              attrs: { type: "hidden", name: "atUserIds" },
                            }),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  title: "",
                                  width: "370",
                                  trigger: "click",
                                },
                                model: {
                                  value: _vm.atListPopoverVisible,
                                  callback: function ($$v) {
                                    _vm.atListPopoverVisible = $$v
                                  },
                                  expression: "atListPopoverVisible",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "atList",
                                    attrs: { "aria-labelledby": "atlist" },
                                  },
                                  [
                                    _c("div", { staticClass: "search" }, [
                                      _c("input", {
                                        attrs: {
                                          autocomplete: "off",
                                          type: "text",
                                          name: "keyword",
                                          placeholder: "查找更多好友",
                                        },
                                        on: {
                                          keyup: _vm.searchUsers,
                                          keydown: _vm.stopEvent,
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "ul",
                                      [
                                        _vm._l(
                                          _vm.users,
                                          function (user, index) {
                                            return [
                                              user.type == 1 &&
                                              index == _vm.users.length - 2
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "bazaSelectMenuTipsBox",
                                                    },
                                                    [_vm._v("小猎或其他用户")]
                                                  )
                                                : _vm._e(),
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "javascript:;",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectPerson(
                                                          user,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm._f("avatar")(
                                                          user.avatar
                                                        ),
                                                        width: "16",
                                                      },
                                                    }),
                                                    user.isFirmTeam
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "team-tip",
                                                          },
                                                          [_vm._v("团队")]
                                                        )
                                                      : _vm._e(),
                                                    _c("span", {
                                                      staticClass: "realName",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          user.realName
                                                        ),
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "nickname",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            user.nickname
                                                              ? `@${user.nickname}`
                                                              : ""
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "at-list-icon",
                                    attrs: {
                                      slot: "reference",
                                      id: "atlist",
                                      title: "对TA说",
                                      href: "javascript:;",
                                    },
                                    on: { click: _vm.getClickAtUserInfo },
                                    slot: "reference",
                                  },
                                  [_vm._v("@")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "comment-image preview clearfix" },
                    _vm._l(_vm.imagePreviewList, function (imagePreview) {
                      return _c("div", { staticClass: "image-item" }, [
                        _c(
                          "img",
                          _vm._b(
                            {},
                            "img",
                            {
                              src: imagePreview.cropPicture.url,
                              "data-thumbnailid": imagePreview.cropPicture.id,
                              "data-bigpictureid": imagePreview.bigPicture.id,
                            },
                            false
                          )
                        ),
                        _c("i", { staticClass: "fa fa-close delete" }),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "comment-attachment preview clearfix" },
                    _vm._l(
                      _vm.attachmentPreviewList,
                      function (attachmentPreview, idx) {
                        return _c(
                          "div",
                          { key: "att_" + idx, staticClass: "attachment-item" },
                          [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-paperclip" }),
                              _c("a", {
                                staticClass: "text-light",
                                attrs: { target: "_blank", title: "下载附件" },
                                domProps: {
                                  textContent: _vm._s(
                                    attachmentPreview.name || "xxx"
                                  ),
                                },
                              }),
                            ]),
                            _c("font-icon", {
                              staticClass: "fa fa-close delete",
                              attrs: { href: "#icon-ic_off_tj1" },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c("div", { staticClass: "comment-operation" }, [
                    _c("div", { staticClass: "col-xs-6 email-operation" }, [
                      _c("span", {
                        staticClass: "text-danger hide",
                        attrs: { id: "error" },
                      }),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEmail,
                              expression: "isEmail",
                            },
                          ],
                          staticClass: "email-checkbox",
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.isEmailNotic,
                                callback: function ($$v) {
                                  _vm.isEmailNotic = $$v
                                },
                                expression: "isEmailNotic",
                              },
                            },
                            [_vm._v("发送邮件通知")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-xs-6 text-right" }, [
                      _c(
                        "input",
                        _vm._b(
                          {
                            staticClass: "btn btn-primary btn-background",
                            attrs: {
                              id: "publish",
                              type: "submit",
                              value: "发表",
                            },
                          },
                          "input",
                          { disabled: !_vm.canPost },
                          false
                        )
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "comments row row-overflow" }, [
                _c(
                  "div",
                  { staticClass: "comment-list" },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.comments, function (item, index) {
                        return _c(
                          "li",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.privacy == 0 ||
                                  (item.privacy == 1 &&
                                    (item.atMe || item.posterId == _vm.userId)),
                                expression:
                                  "item.privacy == 0 || (item.privacy == 1 && (item.atMe || item.posterId == userId))",
                              },
                            ],
                            key: index,
                            staticClass: "clearfix",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "clearfix commentid",
                                attrs: { "data-commentid": item.id },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "pull-left avatar" },
                                  [
                                    _c("avatar", {
                                      attrs: {
                                        size: "md",
                                        src: item.avatarUrl,
                                        userId: item.posterId,
                                        enableCard: true,
                                        enableLink: true,
                                        shareData: _vm.shareData,
                                      },
                                    }),
                                    item.posterIdentityType === 1
                                      ? _c(
                                          "div",
                                          { staticClass: "hr-tip btn-primary" },
                                          [_vm._v("HR")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "detail" }, [
                                  _c("h5", [
                                    _c(
                                      "span",
                                      _vm._b(
                                        {
                                          staticClass: "m-r-5 lbd-user-card",
                                          domProps: {
                                            textContent: _vm._s(
                                              item.posterName
                                            ),
                                          },
                                        },
                                        "span",
                                        { "data-id": item.posterId },
                                        false
                                      )
                                    ),
                                    _c("span", {
                                      staticClass: "m-r-5 nickname",
                                      attrs: { type: "nickname" },
                                      domProps: {
                                        textContent: _vm._s(
                                          "@" + item.posterNickname
                                        ),
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-light pull-right" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("datetime")(item.created)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  item.privacy == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "secret",
                                          attrs: { title: "私密" },
                                        },
                                        [_vm._v("密")]
                                      )
                                    : _vm._e(),
                                  item.hasSticky
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "sticky-top",
                                          attrs: { title: "顶" },
                                        },
                                        [_vm._v("顶")]
                                      )
                                    : _vm._e(),
                                  item.privacy == 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "secret hide",
                                          attrs: { title: "私密" },
                                        },
                                        [_vm._v("密")]
                                      )
                                    : _vm._e(),
                                  _c("span", {
                                    staticClass: "detail-content",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatHtml(
                                          item.content,
                                          item.atUsers
                                        )
                                      ),
                                    },
                                  }),
                                  item.imageList
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "comment-image image-list clearfix",
                                        },
                                        _vm._l(
                                          item.imageList,
                                          function (image) {
                                            return _c(
                                              "div",
                                              _vm._b(
                                                { staticClass: "image-item" },
                                                "div",
                                                { href: image.bigPictureUrl },
                                                false
                                              ),
                                              [
                                                _c(
                                                  "img",
                                                  _vm._b(
                                                    {},
                                                    "img",
                                                    { src: image.thumbnailUrl },
                                                    false
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  item.attachmentList
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "attachment-list clearfix",
                                        },
                                        _vm._l(
                                          item.attachmentList,
                                          function (attachment) {
                                            return _c(
                                              "div",
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "attachment-item",
                                                },
                                                "div",
                                                { href: attachment.fileUrl },
                                                false
                                              ),
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-paperclip text-primary",
                                                }),
                                                _c(
                                                  "a",
                                                  _vm._b(
                                                    {
                                                      staticClass: "text-light",
                                                      attrs: {
                                                        target: "_blank",
                                                        title: "下载附件",
                                                      },
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          attachment.name ||
                                                            "无文件名"
                                                        ),
                                                      },
                                                    },
                                                    "a",
                                                    {
                                                      href: attachment.fileUrl,
                                                    },
                                                    false
                                                  )
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "btn-stage text-light" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "option" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "reply" },
                                            [
                                              _c("font-icon", {
                                                attrs: {
                                                  href: "#icon-xinliweibao-pinglun",
                                                },
                                              }),
                                              _vm._v("回复"),
                                            ],
                                            1
                                          ),
                                          item.isCreator
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "delete invisible",
                                                  },
                                                  [
                                                    _c("font-icon", {
                                                      attrs: {
                                                        href: "#icon-shanchu",
                                                      },
                                                    }),
                                                    _vm._v("删除"),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          item.isShowOperation
                                            ? [
                                                item.hasSticky
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "is-top invisible",
                                                      },
                                                      [
                                                        _c("font-icon", {
                                                          attrs: {
                                                            href: "#icon-zhiding",
                                                          },
                                                        }),
                                                        _vm._v("取消置顶"),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !item.hasSticky
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "not-top invisible",
                                                      },
                                                      [
                                                        _c("font-icon", {
                                                          attrs: {
                                                            href: "#icon-zhiding",
                                                          },
                                                        }),
                                                        _vm._v("置顶"),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "privacy" },
                                        [
                                          item.isCreator
                                            ? [
                                                item.privacy == 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "public invisible",
                                                      },
                                                      [
                                                        _c("font-icon", {
                                                          attrs: {
                                                            href: "#icon-chakan",
                                                          },
                                                        }),
                                                        _vm._v("公开"),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.privacy == 0
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "encrypt invisible",
                                                      },
                                                      [
                                                        _c("font-icon", {
                                                          attrs: {
                                                            href: "#icon-suo1",
                                                          },
                                                        }),
                                                        _vm._v("私密"),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c("divider", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasInternalComment,
                          expression: "hasInternalComment",
                        },
                      ],
                      attrs: { text: "以下评论仅我公司成员可见" },
                    }),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasInternalComment,
                            expression: "hasInternalComment",
                          },
                        ],
                      },
                      _vm._l(_vm.getInternalComments, function (item, index) {
                        return _c("comment-item", {
                          key: index,
                          attrs: { data: item },
                        })
                      }),
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "more hide",
                        attrs: { href: "javascript:;" },
                        on: { click: _vm.getMore },
                      },
                      [
                        _vm._v("加载更多 "),
                        _c("i", { staticClass: "fa fa-angle-double-down" }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "删除评论",
            visible: _vm.deleteDialogVisible,
            width: "500px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.deleteDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("确定删除这条评论吗")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelDelete } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmDeleteLoading,
                      expression: "confirmDeleteLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDelete },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.atNoticeDialogVisible,
            width: "500px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.atNoticeDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("你只@了自己，只有自己才能收到哦！")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelAtNotice } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmAtNoticeLoading,
                      expression: "confirmAtNoticeLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAtNotice },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确定发表吗？",
            visible: _vm.withoutAtDialogVisible,
            width: "320px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.withoutAtDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("你好像想对别人留言，但没有@任何人。"),
            _c("br"),
            _vm._v("@了别人才能收到消息哦！"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelWithoutAt } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.confirmWithoutAtLoading,
                      expression: "confirmWithoutAtLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmWithoutAt },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }